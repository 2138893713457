<template>
  <div class="individualizationTable">
    <el-radio-group v-model="tableType"
                    @change="loadData(1)">
      <el-radio-button label="1">班级目标</el-radio-button>
      <el-radio-button label="2">班级周教学安排</el-radio-button>
      <el-radio-button label="3">班级主题活动资料</el-radio-button>
    </el-radio-group>
    <div class="flex-box">
      <div class="button">
        <el-button type="success"
                   icon="el-icon-plus"
                   @click="addData">添 加</el-button>
        <!-- 无接口 -->
        <el-button type="info"
                   @click="downZip">下 载</el-button>
      </div>
      <div>
        <!-- 管理员 查询上传人和班级-->
        <el-input v-model="username"
                  v-if="roleType"
                  class="input-username"
                  placeholder="请输入上传人" />
        <!-- 普通用户 查询学期 -->
        <term-selector class="input-username"
                       @change="querySemesterChange"
                       v-model="querySemester" />
        <el-cascader v-if="roleType"
                     class="input-username"
                     v-model="queryClassId"
                     :options="gradeList"></el-cascader>

        <el-button type="primary"
                   @click="loadData(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="dataList">
      <el-table-column label="名称"
                       align="center"
                       prop="name"></el-table-column>
      <el-table-column label="学期"
                       align="center"
                       prop="year"></el-table-column>
      <el-table-column label="班级"
                       align="center"
                       prop="cname"></el-table-column>
      <el-table-column label="上传人"
                       align="center"
                       prop="opername"></el-table-column>
      <el-table-column label="备注"
                       align="center"
                       prop="remark"></el-table-column>
      <el-table-column label="操作"
                       width="220px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     size="mini"
                     v-if="scope.row.operid===userInfo.userid"
                     @click="editFrom(scope.row)">编 辑</el-button>
          <el-button type="info"
                     @click="loadFile(scope.row.url)"
                     size="mini">下 载</el-button>
          <el-button type="danger"
                     size="mini"
                     v-if="scope.row.operid===userInfo.userid"
                     @click="deleteData(scope.$index,scope.row.id)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addDataVisible"
               width="600px"
               title="添加资料">
      <el-form label-width="120px"
               ref="dataForm"
               :rules="dataFormRules"
               :model="dataForm">
        <el-form-item label="名称"
                      prop="name">
          <el-input placeholder="请输入名称"
                    v-model="dataForm.name"></el-input>
        </el-form-item>
        <el-form-item label="学期">
          <el-input v-model="dataForm.semester"
                    disabled />
        </el-form-item>
        <el-form-item label="班级"
                      prop="classId">
          <el-cascader v-model="dataForm.classId"
                       placeholder="请选择班级"
                       :disabled="dataForm.id!==''"
                       :options="gradeList"></el-cascader>

        </el-form-item>
        <el-form-item label="资料"
                      prop="fileUrl">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :limit="1"
                     :on-remove="onRemoveFile"
                     ref="upload"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'individualizationTable',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-button type="primary"
                     size="small"
                     class="load-file"
                     @click="loadFile(dataForm.fileUrl)"
                     v-if="dataForm.fileUrl">下载附件</el-button>
        </el-form-item>
        <el-form-item label="备注"
                      prop="remark">
          <el-input type="textarea"
                    placeholder="请输入备注"
                    v-model="dataForm.remark"
                    :autosize="{ minRows: 2 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addDataVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="addDataForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadData"></el-pagination>
  </div>
</template>

<script>
import TermSelector from "@/components/selectors/termSelector";
export default {
  name: "individualizationTable",
  components: { TermSelector },
  data() {
    return {
      tableType: 1,
      queryClassId: 0,
      username: "",
      querySemester: "",
      page: { currentPage: 1, pageCount: 0 },
      dataList: [],
      addDataVisible: false,
      dataForm: {
        name: "",
        semester: "",
        classId: "",
        fileUrl: "",
        remark: "",
        id: -1,
      },
      dataFormRules: {
        name: [{ required: true, message: "请输入活动名称" }],
        classId: [{ required: true, message: "请选择班级" }],
        // fileUrl: [{ required: true, message: "请上传资料" }],
      },
      semesterList: JSON.parse(sessionStorage.getItem("userInfo")).semesters,
      moduleManagementList: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      roleType: false, //是否为模块管理员
      gradeList: [],
      semesterId: -1,
      token: sessionStorage.getItem("token"),
      urlList: [],
    };
  },

  created() {
    this.loadData(1);
    for (let i = 0; i < this.semesterList.length; i++) {
      if (this.semesterList[i].status === "0") {
        this.dataForm.semester = this.semesterList[i].name;
        this.semesterId = this.semesterList[i].id;
      }
    }
    this.loadAllClass(this.semesterId);
    //701个别化教育资料页面
    for (let j = 0; j < this.moduleManagementList.length; j++) {
      if (this.moduleManagementList[j].id === 701) {
        this.roleType = true;
      }
    }
  },
  methods: {
    loadAllUrlList() {
      this.$post("/base/queryClassesDatabase.do", {
        type: this.tableType,
        indexNo: 0,
        roleType: this.roleType ? 1 : 2,
      })
        .then((res) => {
          this.urlList = [];
          this.dataList.forEach((data) => {
            if (data.url) {
              this.urlList.push(data.url);
            }
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadData(page) {
      let data = {
        type: this.tableType,
        indexNo: page,
        roleType: this.roleType ? 1 : 2,
      };
      if (this.username) {
        data.name = this.username;
      }
      if (this.querySemester) {
        data.yearid = this.querySemester;
      }
      if (this.queryClassId) {
        data.classid = this.queryClassId[1];
      }
      this.$post("/base/queryClassesDatabase.do", data)
        .then((res) => {
          this.dataList = res.data.rows;
          this.page.currentPage = res.data.indexNO;
          this.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadAllClass(yearId) {
      this.$post("/media/queryClassesByYear.do", {
        yearid: yearId,
        type: this.roleType ? 1 : 2,
      })
        .then((res) => {
          let gradeList = [];
          res.data.forEach((grade) => {
            let children = [];
            grade.classlist.forEach((cls) => {
              children.push({ value: cls.id, label: cls.cname });
            });
            gradeList.push({ value: grade.id, label: grade.gname, children });
          });
          this.gradeList = gradeList;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    addData() {
      this.addDataVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm.id = "";
        this.$refs.upload.clearFiles();
      });
    },
    addDataForm() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = {
            name: this.dataForm.name,
            type: this.tableType,
            classid: this.dataForm.classId[1],
            remark: this.dataForm.remark,
            url: this.dataForm.fileUrl,
          };
          let apiUrl = "/base/insertClassesDatabase.do";
          if (this.dataForm.id) {
            apiUrl = "/base/updateClassesDatabase.do";
            data = {
              id: this.dataForm.id,
              name: this.dataForm.name,
              remark: this.dataForm.remark,
              url: this.dataForm.fileUrl,
            };
          }
          this.$confirm("是否确认此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.addDataVisible = false;
                  this.loadData(1);
                })
                .catch((err) => {
                  this.addDataVisible = false;
                  this.$message({
                    type: "warning",
                    message: err.message,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          return false;
        }
      });
    },
    editFrom(row) {
      this.addDataVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
        this.dataForm.id = row.id;
        this.dataForm.name = row.name;
        this.dataForm.semester = row.year;
        this.dataForm.classId = row.classid;
        this.dataForm.remark = row.remark;
        this.dataForm.fileUrl = row.url;
      });
    },
    querySemesterChange(value) {
      this.querySemester = value;
      this.queryClassId = "";
      this.loadAllClass(this.querySemester);
    },
    deleteData(index, id) {
      this.$confirm("是否确认此操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteClassesDatabase.do", { id })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.dataList.splice(index, 1);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    enclosureUploadSuccess(res, file) {
      this.dataForm.fileUrl = file.response.message;
    },
    loadFile(url) {
      window.open(this.$fileUploadDomain + url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.dataForm.fileUrl = "";
    },
    // 批量下载
    downZip() {
      this.loadAllUrlList();
      const that = this;
      let paramas = new FormData();
      paramas.append("type", this.urlList);
      this.$postFile("/base/ZipOss.do", this.urlList)
        .then((res) => {
          console.log(res);
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          console.log(error);
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-box {
  margin-top: 20px;
}
.button {
  width: 177px;
}
.input-username {
  margin: 0 5px;
}
</style>
